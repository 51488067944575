import React from "react";
import Footer from "../components/footer";
import App from "../containers/App";
import Image from "react-bootstrap/Image";
import { Card, CardGroup, Container, Row, Col, Button } from "react-bootstrap";

import perfil from "../components/images/docentes/profile.png";

import luiz_antonio_tozi from "../components/images/docentes/luiz_antonio_tozi.png";

import fabiano_sabha_walczak from "../components/images/docentes/fabiano_sabha_walczak.jpg";
import felix_arlindo_strottmann from "../components/images/docentes/felix_arlindo_strottmann.png";
import jose_walmir_goncalves_duque from "../components/images/docentes/jose_walmir_goncalves_duque.png";
import marcus_vinicius_do_nascimento from "../components/images/docentes/marcus_vinicius_do_nascimento.png";
import reinaldo_fagundes_dos_santos from "../components/images/docentes/reinaldo_fagundes_dos_santos.png";
import reinaldo_gen_ichiro_arakaki from "../components/images/docentes/reinaldo_gen_ichiro_arakaki.jpg";
import roque_antonio_de_moura from "../components/images/docentes/roque_antonio_de_moura.jpg";
import viviane_ribeiro_de_siqueira from "../components/images/docentes/viviane_ribeiro_de_siqueira.png";

import ana_lucia_dos_santos from "../components/images/administrativo/ana_lucia_dos_santos.jpg";
import daniel_reinoso_de_morais from "../components/images/administrativo/daniel_reinoso_de_morais.jpg";
import jennifer_ferreira from "../components/images/administrativo/jennifer_ferreira.jpeg";
import luciana_de_abreu_aquino from "../components/images/administrativo/luciana_de_abreu_aquino.png";
import sardes_aparecida_batista from "../components/images/administrativo/sardes_aparecida_batista.jpeg";
import tiago_fogaca_marangoni from "../components/images/administrativo/tiago_fogaca_marangoni.png";

import "./corpo-administrativo.css";


const diretoria = [
  {
    nome: "Luiz Antônio Tozi",
    setor: "Diretor da Unidade",
    imagem: luiz_antonio_tozi,
  },
];

const assistente_diretor = [
  {
    nome: "Luciana de Abreu Aquino",
    setor: "Assessora Técnico Administrativo II",
    imagem: luciana_de_abreu_aquino,
  },
];

const coordenacao = [
  {
    nome: "Fabiano Sabha Walczak",
    curso: "Análise e Desenvolvimento de Sistemas",
    imagem: fabiano_sabha_walczak,
  },
  {
    nome: "José Walmir Gonçalves Duque",
    curso: "Banco de Dados",
    imagem: jose_walmir_goncalves_duque,
  },
  {
    nome: "Reinaldo Gen Ichiro Arakaki",
    curso: "Desenvolvimento de Software Multiplataforma",
    imagem: reinaldo_gen_ichiro_arakaki,
  },
  {
    nome: "Roque Antônio de Moura",
    curso: "Gestão da Produção Industrial",
    imagem: roque_antonio_de_moura,
  },
  {
    nome: "Marcus Vinícius do Nascimento",
    curso: "Logística",
    imagem: marcus_vinicius_do_nascimento,
  },
  {
    nome: "Viviane Ribeiro de Siqueira",
    curso: "Manufatura Avançada",
    imagem: viviane_ribeiro_de_siqueira,
  },
  {
    nome: "Felix Arlindo Strottmann",
    curso: "Manutenção de Aeronaves",
    imagem: felix_arlindo_strottmann,
  },
  {
    nome: "Reinaldo Fagundes dos Santos",
    curso: "Projetos de Estruturas Aeronáuticas",
    imagem: reinaldo_fagundes_dos_santos,
  },
];

const diretoria_academica = [
  {
    nome: "Ana Lúcia dos Santos",
    setor: "Diretora de Serviços Acadêmicos",
    imagem: ana_lucia_dos_santos,
  },
  {
    nome: "Benedito Vitorino Júnior",
    setor: "Agente Técnico e Administrativo",
    imagem: perfil,
  },
  {
    nome: "Martim Dias de Oliveira",
    setor: "Agente Técnico e Administrativo",
    imagem: perfil,
  },
  {
    nome: "Tiago Fogaça Marangoni",
    setor: "Agente Técnico e Administrativo",
    imagem: tiago_fogaca_marangoni,
  },
];

const diretoria_administrativa = [
  {
    nome: "Anna Hiromi Sogabe",
    setor: "Diretora de Serviços Administrativos",
    imagem: perfil,
  },
  {
    nome: "Patrícia Fernandes da Silva",
    setor: "Assessora Administrativo",
    imagem: perfil,
  },
  {
    nome: "Carolina Fcamidu Coff",
    setor: "Agente Técnico e Administrativo",
    imagem: perfil,
  },
  {
    nome: "Leonardo Vinícius Guimarães",
    setor: "Agente Técnico e Administrativo",
    imagem: perfil,
  },
  {
    nome: "Matheus de Souza Ferreira da Silva",
    setor: "Agente Técnico e Administrativo",
    imagem: perfil,
  },
];

const biblioteca = [
  {
    nome: "Sardes Aparecida Batista",
    setor: "Analista de Suporte e Gestão (Bibliotecária)",
    imagem: sardes_aparecida_batista,
  },
  {
    nome: "Daniel Reinoso de Morais",
    setor: "Agente Técnico e Administrativo",
    imagem: daniel_reinoso_de_morais,
  },
  {
    nome: "Jennifer Ferreira",
    setor: "Agente Técnico e Administrativo",
    imagem: jennifer_ferreira,
  },
];

const informatica = [
  {
    nome: "Marcos Issamu Kokehara",
    setor: "Analista de Suporte e Gestão (Tecnólogo em Informática)",
    imagem: perfil,
  },
];

const auxiliar_docente = [
  {
    nome: "Alexandre Santos Oliveira",
    setor: "Auxiliar de Docente (Informática)",
    imagem: perfil,
  },
  {
    nome: "Cristiano Júlio Barreira",
    setor: "Auxiliar de Docente (Informática)",
    imagem: perfil,
  },
  {
    nome: "Renato de Menezes Quintino",
    setor: "Auxiliar de Docente (Aeronáutica)",
    imagem: perfil,
  },
  {
    nome: "Valdemir José da Costa",
    setor: "Auxiliar de Docente (Elétrica)",
    imagem: perfil,
  },
];

const corpo_adm = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <p className="corpo-adm-titulo">CORPO ADMINISTRATIVO</p>
        </Col>
      </Row>
    </Container>
	
    <Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row style={{ marginBottom: "40px" }}>
        <Col style={{ marginBottom: "15px" }} md={{ span: 12 }}>
          <h2 className="corpo-adm-h2">Diretoria da Unidade</h2>
        </Col>
        <Col style={{ marginBottom: "20px" }} md={10}>
		  <p>
			A Diretoria é o órgão executivo encarregado de dirigir e coordenar
			as atividades da Faculdade.<br></br>
		  </p>
		  <Row xs={1} md={4} className="g-4">
		  {diretoria.map((obj) => (
		    <Col>
			  <Card className="doc_card">
			    <Card.Body>
				  <Card.Title>
				    <Image
					  className="image"
					  roundedCircle
					  src={obj.imagem}
					  width={150}
					  height={150}
				    ></Image>
				  </Card.Title>
				  <Card.Text>{obj.nome}</Card.Text>
				  <Card.Text>{obj.setor}</Card.Text>
			    </Card.Body>
			  </Card>
		    </Col>
		  ))}
		  </Row>
		</Col>
      </Row>
      <hr></hr>

      <Row style={{ marginBottom: "40px" }}>
        <Col style={{ marginBottom: "15px" }} md={{ span: 12 }}>
          <h2 className="corpo-adm-h2">Assistente do Diretor</h2>
        </Col>
        <Col style={{ marginBottom: "20px" }} md={10}>
		  <Row xs={1} md={4} className="g-4">
		  {assistente_diretor.map((obj) => (
		    <Col>
			  <Card className="doc_card">
			    <Card.Body>
				  <Card.Title>
				    <Image
					  className="image"
					  roundedCircle
					  src={obj.imagem}
					  width={150}
					  height={150}
				    ></Image>
				  </Card.Title>
				  <Card.Text>{obj.nome}</Card.Text>
				  <Card.Text>{obj.setor}</Card.Text>
			    </Card.Body>
			  </Card>
		    </Col>
		  ))}
		  </Row>
		</Col>
      </Row>
      <hr></hr>
	  
      <Row style={{ marginBottom: "40px" }}>
        <Col style={{ marginBottom: "15px" }} md={{ span: 12 }}>
          <h2 className="corpo-adm-h2">Coordenação de Cursos</h2>
        </Col>
        <Col style={{ marginBottom: "20px" }} md={10}>
		  <Row xs={1} md={4} className="g-4">
		  {coordenacao.map((obj) => (
		    <Col>
			  <Card className="doc_card">
			    <Card.Body>
				  <Card.Title>
				    <Image
					  className="image"
					  roundedCircle
					  src={obj.imagem}
					  width={150}
					  height={150}
				    ></Image>
				  </Card.Title>
				  <Card.Text>{obj.nome}</Card.Text>
				  <Card.Text>{obj.curso}</Card.Text>
			    </Card.Body>
			  </Card>
		    </Col>
		  ))}
		  </Row>
		</Col>
      </Row>
      <hr></hr>

      <Row style={{ marginBottom: "40px" }}>
        <Col style={{ marginBottom: "15px" }} md={{ span: 12 }}>
          <h2 className="corpo-adm-h2">Diretoria de Serviços Acadêmicos</h2>
        </Col>
        <Col style={{ marginBottom: "20px" }} md={10}>
		  <p>
			A Secretaria Acadêmica é o órgão de apoio da Direção, onde se centralizam os registros dos serviços administrativos concernentes ao regime didático e escolar da Faculdade.<br />
			É o órgão responsável, entre outras coisas, pela emissão de documentos e registros de notas.<br></br>
		  </p>
		  <Row xs={1} md={4} className="g-4">
		  {diretoria_academica.map((obj) => (
		    <Col>
			  <Card className="doc_card">
			    <Card.Body>
				  <Card.Title>
				    <Image
					  className="image"
					  roundedCircle
					  src={obj.imagem}
					  width={150}
					  height={150}
				    ></Image>
				  </Card.Title>
				  <Card.Text>{obj.nome}</Card.Text>
				  <Card.Text>{obj.setor}</Card.Text>
			    </Card.Body>
			  </Card>
		    </Col>
		  ))}
		  </Row>
		</Col>
      </Row>
      <hr></hr>
	  
	  <Row style={{ marginBottom: "40px" }}>
        <Col style={{ marginBottom: "15px" }} md={{ span: 12 }}>
          <h2 className="corpo-adm-h2">Diretoria de Serviços Administrativos</h2>
        </Col>
        <Col style={{ marginBottom: "20px" }} md={10}>
		  <p>
			A Diretoria de Serviços Administrativos é o órgão responsável pela direção e coordenação das atividades de suporte administrativo e operacional ao desenvolvimento do
			ensino e da pesquisa, promovendo, entre outras atividades, as medidas administrativas necessárias à defesa e preservação dos bens patrimoniais.<br></br>
		  </p>
		  <Row xs={1} md={4} className="g-4">
		  {diretoria_administrativa.map((obj) => (
		    <Col>
			  <Card className="doc_card">
			    <Card.Body>
				  <Card.Title>
				    <Image
					  className="image"
					  roundedCircle
					  src={obj.imagem}
					  width={150}
					  height={150}
				    ></Image>
				  </Card.Title>
				  <Card.Text>{obj.nome}</Card.Text>
				  <Card.Text>{obj.setor}</Card.Text>
			    </Card.Body>
			  </Card>
		    </Col>
		  ))}
		  </Row>
		</Col>
      </Row>
      <hr></hr>
	  
      <Row style={{ marginBottom: "40px" }}>
        <Col style={{ marginBottom: "15px" }} md={{ span: 12 }}>
          <h2 className="corpo-adm-h2">Biblioteca</h2>
        </Col>
        <Col style={{ marginBottom: "20px" }} md={10}>
		  <Row xs={1} md={4} className="g-4">
		  {biblioteca.map((obj) => (
		    <Col>
			  <Card className="doc_card">
			    <Card.Body>
				  <Card.Title>
				    <Image
					  className="image"
					  roundedCircle
					  src={obj.imagem}
					  width={150}
					  height={150}
				    ></Image>
				  </Card.Title>
				  <Card.Text>{obj.nome}</Card.Text>
				  <Card.Text>{obj.setor}</Card.Text>
			    </Card.Body>
			  </Card>
		    </Col>
		  ))}
		  </Row>
		</Col>
      </Row>
      <hr></hr>
	  
      <Row style={{ marginBottom: "40px" }}>
        <Col style={{ marginBottom: "15px" }} md={{ span: 12 }}>
          <h2 className="corpo-adm-h2">Departamento de Informática</h2>
        </Col>
        <Col style={{ marginBottom: "20px" }} md={10}>
		  <Row xs={1} md={4} className="g-4">
		  {informatica.map((obj) => (
		    <Col>
			  <Card className="doc_card">
			    <Card.Body>
				  <Card.Title>
				    <Image
					  className="image"
					  roundedCircle
					  src={obj.imagem}
					  width={150}
					  height={150}
				    ></Image>
				  </Card.Title>
				  <Card.Text>{obj.nome}</Card.Text>
				  <Card.Text>{obj.setor}</Card.Text>
			    </Card.Body>
			  </Card>
		    </Col>
		  ))}
		  </Row>
		</Col>
      </Row>
      <hr></hr>
	  
	  <Row style={{ marginBottom: "40px" }}>
        <Col style={{ marginBottom: "15px" }} md={{ span: 12 }}>
          <h2 className="corpo-adm-h2">Auxiliar de Docente</h2>
        </Col>
        <Col style={{ marginBottom: "20px" }} md={10}>
		  <Row xs={1} md={4} className="g-4">
		  {auxiliar_docente.map((obj) => (
		    <Col>
			  <Card className="doc_card">
			    <Card.Body>
				  <Card.Title>
				    <Image
					  className="image"
					  roundedCircle
					  src={obj.imagem}
					  width={150}
					  height={150}
				    ></Image>
				  </Card.Title>
				  <Card.Text>{obj.nome}</Card.Text>
				  <Card.Text>{obj.setor}</Card.Text>
			    </Card.Body>
			  </Card>
		    </Col>
		  ))}
		  </Row>
		</Col>
      </Row>
      <hr></hr>
	  
    </Container>
    <Footer />{" "}
  </App>
);

export default corpo_adm;
