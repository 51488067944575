import React from "react";
import { Card, CardGroup, Container, Row, Col, Table } from "react-bootstrap";
import Footer from "../components/footer";
import App from "../containers/App";
import "./catalogo-de-tg.css";

const catalogotg = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col style={{ marginTop: "30px" }}>
          <p className="catalogotg-titulo">Catálogo de TG</p>
        </Col>
      </Row>
    </Container>

    <Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row>
        <hr />
        <Col md={{ span: 12 }} style={{ marginBottom: "40px" }}>
          <p className="catalogotg-subtitulo" style={{ marginTop: "50px" }}>
            Cursos
          </p>
          <p className="catalogotg-p">
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/catalogo-tg/catalogo-tg-automacao-aero.php"
            >
              - Automação Aeronáutica
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/catalogo-tg/catalogo-tg-amd.php"
            >
              - Automação e Manufatura Digital
            </a>
            <br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/catalogo-tg/catalogo-tg-mnf-aero.php"
            >
              - Manufatura Aeronáutica
            </a>
            <br />
			<a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/catalogo-tg/catalogo-tg-mnt.php"
            >
              - Manutenção de Aeronaves
            </a>
			<br />
            <a
              id="cor2"
              target="_blank" rel="noopener noreferrer"
              href="https://sjc.fatec.sp.gov.br/downloads/catalogo-tg/catalogo-tg-pea.php"
            >
              - Projetos de Estruturas Aeronáuticas
            </a>
            <br />
          </p>
        </Col>
      </Row>
    </Container>
    <Footer />
  </App>
);

export default catalogotg;
