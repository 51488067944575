import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./styles.module.css";



const Instafeed = () => (
<p style={{ padding: 5, margin: 0 }}>
<iframe
          src="https://padlet.com/embed/h3v6fwnn4kq8p5nb/slideshow?autoplay=1&loop=1&duration=5" title="painel padlet"
          allow="clipboard-write"
          style={{
            width: "100%",
            height: "608px",
            display: "block",
            padding: 0,
            margin: 0,
          }}
        ></iframe>
</p>

);

export default Instafeed;
