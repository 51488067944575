import React from "react";

import App from "../containers/App";

const vestibular = () => (
  <App>
    <h1>Vestibular</h1>
  </App>
);

export default vestibular;
