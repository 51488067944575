import React from "react";
import { Col, Container, Image } from "react-bootstrap";
import cps from "../images/cps.png";
import governosp from "../images/governo.png";
import logo from "../images/logo.png";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
  FaEnvelope,
} from "react-icons/fa";

import "./Footer.css";

const Footer = () => (
  <div class="footer-dark">
    <footer>
      <div class="container">
	  
        <div class="row">
          <div class="col-sm-6 col-md-12 item">
            <a href="/"><Image className="images" roundedCircle src={logo} alt=""/></a>
            <a href="https://www.cps.sp.gov.br/" target="_blank" rel="noopener noreferrer"><Image className="images" roundedCircle src={cps} /></a>
            <a href="https://www.saopaulo.sp.gov.br/" target="_blank" rel="noopener noreferrer"><Image className="images" roundedCircle src={governosp} /></a>
          </div>
        </div>
		
        <div class="row">
          <div class="col-md-12 item text">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/geral_institucional">Institucional</a></li>
              <li><a href="/geral_cursos">Cursos</a></li>
              <li><a href="/geral_projetos">Projetos</a></li>
              <li><a href="/geral_alunos">Área do Aluno</a></li>
              <li><a href="/contato">Contato</a></li>
            </ul>
          </div>
        </div>
		
        <div class="row">
          <div class="col item social">
            <a href="https://www.facebook.com/fatecjessenvidal" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
            <a href="https://www.linkedin.com/school/fatecjessenvidal" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
            <a href="https://www.instagram.com/fatecsaojosedoscampos/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://www.youtube.com/channel/UCRTd_ST0vPqXi4w_ZdfXtiw" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
            <a href="/contato"><FaEnvelope /></a>
          </div>
        </div>

        <p class="copyright">
          Faculdade de Tecnologia de São José dos Campos - Prof. Jessen Vidal
        </p>
      </div>
    </footer>
  </div>
);

export default Footer;
