import React from "react";
import Footer from "../components/footer";
import App from "../containers/App";
import { Card, Container, Row } from "react-bootstrap";
import "./secretaria.css";
import tabelaDocumentos from "../components/images/tabelaDocumentos.png";

const secretaria = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <p className="sct-titulo">SECRETARIA ACADÊMICA</p>
    </Container>
    <Container>
      <Card style={{ boxShadow: "0 0 0 0", border: "0 none", outline: "0" }}>
        <Row>
          <p id="subtitulo">Atendimento</p>
          <p id="espaco">
			Horário de Funcionamento:<br /><br />
			
			- Até 02/08/2024 - De segunda a sexta das <mark id="marca">08h00 às 17h00</mark><br />
			- A partir de 05/08/2024 - De segunda a sexta das <mark id="marca">08h00 às 21h30</mark><br /><br />
			
			Canais de atendimento:<br />
			
			- Presencial na Unidade<br />
			- Telefones: (12) 3905-4799 / (12) 3905-2423 / (12) 3905-4699<br />
			- E-mail: f146.secretaria@fatec.sp.gov.br<br />
			- Não realizamos atendimentos por meio de contas pessoais ou de outras plataformas (Teams, Instagram, Facebook, WhatsApp)<br />
			
		  </p>
		  
          <p id="subtitulo">Descrição dos Serviços</p>
          <p id="espaco">
            Os serviços da Secretaria Acadêmica desenvolvem-se na forma de atos administrativos ou expedição de documentos, classificam-se em ordinários e extraordinários. Segue abaixo algumas de nossas atribuições:<br /><br />
				
			- Emissão de documentos acadêmicos;<br />
			- Eliminações por meio de Exame ou Aproveitamento de Estudos;<br />
			- Matrículas de Ingressantes;<br />
			- Desistência de Disciplina;<br />
			- Trancamento de Matrícula;<br />
			- Cancelamento de Matrícula;<br />
			- Transferências;<br />
			- Matrículas de Veteranos;<br />
			- Passe escolar;<br />
			- Assuntos relacionados a Estágio;<br />
			- Solicitações diversas;<br /><br />
				
			Tempo máximo para atendimento de solicitações: 05 dias úteis.<br />

		  </p>


          <p id="subtitulo">Emissão de documentos Acadêmicos</p>
          <p id="espaco">
            Poderão ser solicitados via SIGA ou via e-mail os seguintes documentos acadêmicos:<br /><br />
				
			- Atestados de Matrícula disponíveis no SIGA;<br />
			- Atestado ou Certificado de Conclusão de Curso;<br />
			- Histórico Escolar;<br />
			- Ementas (Conteúdo Programático);<br />
			- Outros...<br />
			
          </p>

          <p id="subtitulo">Exame de Proficiência e Aproveitamento de Estudos</p>
          <p id="espaco">
            A solicitação de Exame de Proficiência e/ou e Aproveitamento de Estudos junto com a documentação necessária (Histórico e Ementas de outras Instituições) deve ser enviada pelo SIGA. Após a conferência, é encaminhada aos departamentos de curso para análise curricular e aplicações de exames. Os pareceres são arquivados junto com o prontuário do aluno.<br />
          </p>

          <p id="subtitulo">Matrícula Inicial</p>
          <p id="espaco">
            Ingresso via Vestibular: <br /><br />
			
			- Recebemos a solicitação de matrícula e cópia de documentação através do SIGA;<br />
			- Através da tela de Matrícula Remota realizamos o deferimento;<br />
			- Matrícula realizada automática em todas as disciplinas do 1º semestre do curso;<br /><br />
			
			Ingresso via Vagas remanescentes: <br /><br />
			
			- Recebemos a solicitação de matrícula/transferência e cópia de documentação presencialmente;<br />
			- Através de análise curricular as coordenações realizam o deferimento;<br />
			- Matrícula realizada automática em todas as disciplinas sugeridas;<br /><br />
			
			Após ingresso<br /><br />
			
			- Automaticamente cria-se Usuários e Senhas para acesso e os e-mails institucionais;<br />
			- Solicitaremos documentação física após o primeiro mês de aula.<br />
			
          </p>
		  
		  <p id="subtitulo">Abono de Faltas</p>
          <p id="espaco">
            Não há abono de faltas, exceto nos seguintes casos:<br /><br />
			
			- Convocação para cumprimento de serviços obrigatórios por lei;<br />
			- Exercício de representação estudantil em órgãos colegiados, nos horários em que estes se reúnem;<br />
			- Falecimento de cônjuge, filho, pais ou padrastos e irmãos, 3 (três) dias;<br />
			- Falecimento de avós, sogros e cunhados, 2 (dois) dias.<br /><br />
				
			Em qualquer dos casos previstos, deverá haver comprovação e o pedido deve ser encaminhado via e-mail.<br />

          </p>
		  
          <p id="subtitulo">Desistência de Disciplinas</p>
          <p id="espaco">
            O estudante poderá solicitar desistência no período determinado no calendário acadêmico, obedecendo o limite mínimo de disciplinas a serem cursadas no semestre. A solicitação deve ser feita através do e-mail da secretaria para podermos registrar no SIGA. Não serão aceitas a desistência caso o aluno já tenha solicitado anteriormente, ou seja, uma disciplina de matrícula (artigo 42 do regulamento das Fatecs).<br />
          </p>
		  
          <p id="subtitulo">Trancamento de Matrícula</p>
          <p id="espaco">
            Cada aluno tem direito a dois trancamentos (2 semestres) consecutivos ou não podendo ser solicitado de acordo com as datas estipuladas no calendário. A solicitação deve ser feita através do e-mail da secretaria e informado o motivo da solicitação. O aluno que por qualquer motivo não efetuar sua rematrícula em pelo menos uma disciplina terá trancamento automático na sua matrícula naquele semestre letivo, caso não tenha utilizado anteriormente os dois trancamentos previstos no regulamento das Fatecs.<br />
          </p>
		  
          <p id="subtitulo">Cancelamento de Matrícula</p>
          <p id="espaco">
            A matrícula de um aluno será cancelada quando:<br /><br />
			
			- O aluno aprovado via vestibular não comparecer às aulas nas duas primeiras semanas do semestre em que ingressou na Faculdade;<br />
			- O ingressante não obter aprovação em nenhuma disciplina do 1º semestre, não computadas dispensas (aproveitamentos de estudos e/ou exames de proficiência) eventualmente concedidas;<br />
			- Aluno que após 6 semestres de matrícula não tiver obtido um Percentual de Progressão superior a 33%;<br />
			- O aluno não concluir seu curso de graduação no prazo máximo fixado para a sua integralização, não computados os trancamentos de matrícula;<br />
			- O aluno solicitar o trancamento por escrito via e-mail;<br />
			- O aluno não confirmar o trancamento automático previsto neste regulamento;<br />
			- O aluno for enquadrado em situação de trancamento, não tendo mais direito a nenhum trancamento;<br />
			- A FATEC tomar conhecimento de que o aluno está matriculado em outra instituição pública de ensino superior;<br />
			- O aluno for condenado à pena de expulsão em processo disciplinar.<br />

          </p>
		  
          <p id="subtitulo">Transferência</p>
          <p id="espaco">
            Para solicitações, o período previsto ocorrerá de 22 a 26 de julho de 2024, através de formulário eletrônico publicado em nosso Site Oficial.<br />
            A divulgação dos resultados ocorrerá somente via e-mail, após às 15h do dia 02 de agosto de 2024, ou de acordo com o processamento das análise.<br />
            Requisitos:<br /><br />
			
			- Estar em curso ou ser concluinte de curso Superior de Graduação;<br />
			- Aprovações que possibilitem eliminação de mais de meio semestre do curso pretendido;<br />
			- Ter tempo mínimo para integralização (se for estudante de Fatec).<br />
			- Apresentar no ato da solicitação (exceto oriundos da Fatec SJC):<br />
				- Atestado de Matrícula;<br />
				- Histórico Escolar;<br />
				- Ementas (ou conteúdo programático) das disciplinas cursadas.<br />
				
          </p>
		  
          <p id="subtitulo">Matrícula de Veteranos (Rematrícula)</p>
          <p id="espaco">
            Configuramos o SIGA para recebimento das matrículas (configuração de matrícula, lançamento de horários e calendário e o desbloqueio dos alunos).<br />
            As matrículas são processadas pelo SIGA de acordo com os dispostos no Regulamento Geral dos Cursos de Graduação das Fatecs.<br />
            A matrícula de veteranos é obrigatória em cada período letivo, de acordo com o Calendário Acadêmico, sendo que a ausência de matrícula em um período letivo implica no trancamento automático da matrícula (caso o aluno ainda tenha direito).<br />

          </p>
		  
          <p id="subtitulo">Passe Escolar</p>
          <p id="espaco">
            Estudantes que residem em São José dos Campos, deverão se cadastrar no <a id="cor" target="_blank" rel="noopener noreferrer" href="https://www.consorcio123sistema.com.br/sce">Sistema de Cadastro de Estudantes do Consórcio 123</a>, preenchendo as informações. Caso as informações estejam corretas, a Secretaria Acadêmica realizará a liberação para que possa realizar a compra do Passe Escolar.<br />
			No caso de estudantes de outras cidades, deverá solicitar à escola o cadastro no Portal Parceiros. Informar se é Meia Tarifa ou Passe Livre; o professor, apenas o Meia Tarifa. Após o cadastro ser realizado pela Secretaria Acadêmica, o estudante deverá preencher o <a id="cor" target="_blank" rel="noopener noreferrer" href="https://www.emtu.sp.gov.br/passe/index.htm?regiao=257">formulário de Solicitação no site da EMTU</a>.<br />

          </p>
		  
		  <p id="subtitulo">Sobre Estágio</p>
          <p id="espaco">
            Somos responsáveis pelo recebimento, registro e encaminhamento para assinatura e devolução de contratos de estágio e acordos de cooperação. Relatórios de estágios finalizados devem ser encaminhados ao coordenador do curso. Na aba Estágios você pode encontrar mais informações. Segue abaixo alguns tópicos sobre estágio, conforme a <a id="cor" target="_blank" rel="noopener noreferrer" href="https://www.planalto.gov.br/ccivil_03/_ato2007-2010/2008/lei/l11788.htm">Lei nº 11.788 de 25/09/2008</a>:<br /><br />
			
			- Poderá ser obrigatório (requisito para aprovação) ou não-obrigatório (atividade opcional) (Art. 2);<br />
			- Requer matrícula e frequência regular, celebração de termo de compromisso e compatibilidade entre as atividades (Art. 3);<br />
			- A jornada não pode ultrapassar 6 (seis) horas diárias e 30 (trinta) horas semanais. 40 (quarenta) horas apenas se previsto no Projeto Pedagógico. A redução da carga horária do estágio apenas em período de avaliação. (Art. 10);<br />
			- É assegurado recesso remunerado de 30 (trinta) dias (Art. 13);<br />
			- A manutenção de estagiários em desconformidade com esta Lei caracteriza vínculo de emprego (Art. 15);<br />
			- O termo de compromisso deverá ser firmado pelo estagiário, pelos representantes da parte concedente e da instituição de ensino (Art. 16).<br />
	
          </p>
        </Row>
      </Card>
    </Container>
    <Footer />
  </App>
);

export default secretaria;
