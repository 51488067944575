import React from "react";
import Footer from "../components/footer";
import App from "../containers/App";
import { Card, Container, Row } from "react-bootstrap";
import "./editais.css";

const editais = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <p className="edit-titulo">EDITAIS</p>
    </Container>
	
	
   <Container>
		<Card
			style={{
			marginRight: "50px",
			boxShadow: "0 0 0 0",
			border: "0 none",
			outline: "0",
			}}
		>
	  
		<Row>
		  <font size="4">
		  <p id="espaco">
		  Nada consta.
		  </p>
          {/*<p id="espaco">
            <a id="cor" href="https://sjc.fatec.sp.gov.br/downloads/editais/2024/027-2024 Ética Profissional e Patente - DSM.pdf" target="_blank" rel="noopener noreferrer">
				Edital determinado nº 027/2024</a> – <b><u>Ética Profissional e Patente</u></b> – 02 horas-aula - <b><u>Curso Desenvolvimento de Software Multiplataforma - noite</u></b><br />
				Período de inscrições: <mark>de 16/05/2024 a 20/05/2024 (03 dias úteis), até às 23h59</mark>
          </p>
		  
          <p id="espaco">
            <font id="cor">IMPORTANTE:</font><br />
            Para realizar a inscrição o docente deverá encaminhar, <u>exclusivamente</u>, para o e-mail <mark id="marca">f146editais@cps.sp.gov.br</mark> a seguinte documentação:<br />
            A) Ficha de manifestação de interesse (anexo VI da Instrução CESU nº 14/2023);<br />
            B) Tabela de pontuação docente (Anexo IV) da Instrução CESU nº 14/2023, preenchida eletronicamente, sendo o preenchimento de exclusiva responsabilidade do docente interessado; e<br />
            C) Documentação comprobatória das atividades/itens elencados na tabela de pontuação, devendo cada comprovante ser numerado, de modo a informar no anexo IV a sua correspondência.
         </p>
		  
          <p id="espaco">
            Link para download dos modelos a serem preenchidos e enviados para a inscrição:<br />
            <a id="cor" href="https://cesu.cps.sp.gov.br/concursos-publicos-pss-e-ampliacao/" target="_blank" rel="noopener noreferrer">
				https://cesu.cps.sp.gov.br/concursos-publicos-pss-e-ampliacao/
            </a>
          </p>
		  
          <p id="espaco">
            Verificar a <a id="cor" href="https://cesu.cps.sp.gov.br/wp-content/uploads/2023/11/Instrucao-Cesu-n-14-2023.docx" target="_blank" rel="noopener noreferrer">Instrução CESU nº 14 de 22/11/2023</a>,
			a <a id="cor" href="https://cesu.cps.sp.gov.br/wp-content/uploads/2023/11/Portaria-Cesu-n.-6-2023.docx" target="_blank" rel="noopener noreferrer">Portaria CESU nº 06 de 22/11/2023</a> e
			a <a id="cor" href="https://cesu.cps.sp.gov.br/wp-content/uploads/2023/11/Deliberacao-CEETEPS-98_2023-11-22.pdf" target="_blank" rel="noopener noreferrer">Deliberação CEETEPS 98 de 09/11/2023</a>,
			que dispõem sobre procedimentos e critérios para a alteração de carga horária de docentes.
		  </p>*/}
		  </font>
        </Row>
		
      </Card>
    </Container>
	
    <Footer />
  </App>
);

export default editais;
