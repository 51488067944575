import React from "react";
import App from "../../containers/App";
import Footer from "../../components/footer";
import {
  Card,
  CardGroup,
  Container,
  Row,
  Col,
  ListGroup,
} from "react-bootstrap";
import "../sobre-cursos.css";

const geral_institucional = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "10px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <p className="sbr-titulo">INSTITUCIONAL</p>
        </Col>
      </Row>
    </Container>

    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "100px",
      }}
    >
      <Row xs={1} md={11} className="g-4">
        <Col>
          <p className="sbr-list-titulo">Lista de Opções</p>
          <ListGroup variant="flush">
            <a className="sbr-links" href="/sobre">
              <ListGroup.Item>A Fatec</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/achados-e-perdidos">
              <ListGroup.Item variant="warning">
                Achados e Perdidos
              </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/biblioteca">
              <ListGroup.Item>Biblioteca</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/cadi">
              <ListGroup.Item variant="warning">CADI</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/cipa">
              <ListGroup.Item>CIPA</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/calendario-digital-de-eventos">
              <ListGroup.Item variant="warning">
                Calendário Digital de Eventos
              </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/concurso">
              <ListGroup.Item>Concurso Público</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/cpa">
              <ListGroup.Item variant="warning">
                CPA-Comissão Própria de Avaliação
              </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/corpo-administrativo">
              <ListGroup.Item>Corpo Administrativo</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/docentes">
              <ListGroup.Item variant="warning">Docentes</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/editais">
              <ListGroup.Item>Editais</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/galeria">
              <ListGroup.Item variant="warning">Galeria</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/laboratorios-de-informatica">
              <ListGroup.Item>Laboratórios</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/secretaria">
              <ListGroup.Item variant="warning">Secretaria</ListGroup.Item>
            </a>
          </ListGroup>
        </Col>
      </Row>
    </Container>

    <Footer />
  </App>
);

export default geral_institucional;
