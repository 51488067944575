import React from "react";
import Footer from "../components/footer";
import Image from "react-bootstrap/Image";
import App from "../containers/App";
import {
  Card,
  Container,
  Row,
  Col
} from "react-bootstrap";
import logo_cipa from "../components/images/CIPA.png";
import perfil from "../components/images/docentes/profile.png";
import Cartaz_Cipa from "../components/images/Cartaz_Cipa_Copams_Juntos_contra_o_assedio.jpg";
import imagem_epi from "../components/images/imagem_epi_gpi.png";

/* Membros CIPA */
import joares_lidovino_dos_reis from "../components/images/docentes/joares_lidovino_dos_reis.jpg";
import lucas_giovanetti from "../components/images/docentes/lucas_giovanetti.jpg";
import luciana_de_abreu_aquino from "../components/images/administrativo/luciana_de_abreu_aquino.png";
import marcos_da_silva_e_souza from "../components/images/docentes/marcos_da_silva_e_souza.jpg";


import "./cipa.css";

const titulares = [
  {
    nome: "Lucas Giovanetti - Presidente (Empregador)",
    imagem: lucas_giovanetti,
  },
  {
    nome: "Joares Lidovino dos Reis - Vice Presidente (Eleito)",
    imagem: joares_lidovino_dos_reis,
  },
];

const suplentes = [
  {
    nome: "Luciana de Abreu Aquino (Empregador)",
    imagem: luciana_de_abreu_aquino
  },
  {
    nome: "Marcos Da Silva e Souza (Eleito)",
    imagem: marcos_da_silva_e_souza,
  },
];

/* Variáveis para cálculo de dias sem acidente */
const dataInicial = new Date("2019-6-17");
const dataAtual = new Date();
const diferenca = dataAtual.getTime() - dataInicial.getTime();
const totalDias = Math.floor(diferenca / (1000 * 60 * 60 * 24));


const cipa = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <img src={logo_cipa} alt=""></img>
        </Col>
      </Row>
    </Container>
    <Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row>
        <Col md={{ span: 12 }}>
          <p className="cipa-p">
            A CIPA é formada pelos próprios funcionários e professores, com o
            objetivo de prevenir acidentes e doenças decorrentes do trabalho.
          </p>
        </Col>
      </Row>
      <Row>
        <Col style={{textAlign: "center",}}>
          <img width="50%" src={Cartaz_Cipa} alt=""></img>
        </Col>
		<Col style={{textAlign: "center",}}>
          <img width="70%" src={imagem_epi} alt=""></img>
        </Col>
      </Row>
	  <br />
	  <br />
	  
	  
	  
	  <hr />
      <Row style={{ margin: "30px" }}>
        <Col>
          <h2 className="cipa-h2">
            Membros da CIPA
            <br />
            Fatec São José dos Campos
            <br />
            Gestão 2023/2024
          </h2>
        </Col>
      </Row>
	  
      <Row style={{ margin: "30px" }}>
        <Col>
          <h2 className="cipa-h2">Titulares</h2>
        </Col>
      </Row>

      <Row xs={1} md={4} className="g-4">
	    {titulares.map((obj) => (
          <Col>
            <a className="nome" target="_blank" rel="noopener noreferrer" href={obj.cv}>
              <Card className="doc_card">
                <Card.Body>
                  <Card.Title>
                    <Image
						className="image"
						roundedCircle
						src={obj.imagem}
						width={150}
						height={150}
					></Image>
                  </Card.Title>
                  <Card.Text>{obj.nome}</Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
        ))}
	  </Row>



      <Row style={{ margin: "30px" }}>
        <Col>
          <h2 className="cipa-h2">Suplentes</h2>
        </Col>
      </Row>

      <Row xs={1} md={4} className="g-4">
	    {suplentes.map((obj) => (
          <Col>
            <a className="nome" target="_blank" rel="noopener noreferrer" href={obj.cv}>
              <Card className="doc_card">
                <Card.Body>
                  <Card.Title>
                    <Image
						className="image"
						roundedCircle
						src={obj.imagem}
						width={150}
						height={150}
					></Image>
                  </Card.Title>
                  <Card.Text>{obj.nome}</Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
        ))}
	  </Row>
	  <br />
	  <br />
	  
	  
      <Row style={{ margin: "30px" }}>
        <Col>
          <h2 className="cipa-h2">Significado e competências</h2>
          <br></br>
          <p className="cipa-p">
            CIPA: Comissão Interna de Prevenção de Acidentes, que tem a
            finalidade de prevenir acidentes e doenças decorrentes do trabalho,
            de modo a tornar compatível permanentemente o trabalho com a
            preservação da vida e a promoção da saúde do trabalhador.
          </p>
          <p className="cipa-p">
            <a
              id="cor"
              target="_blank" rel="noopener noreferrer"
              href="https://fatecsjc-prd.azurewebsites.net/downloads/cipa/2015.07.25_folder_cipa.pdf"
            >
              Clique Aqui
            </a>{" "}
            e veja o folder informativo que contêm mais detalhes sobre a CIPA,
            como tarefas, composição e competências.
          </p>
        </Col>
      </Row>

      <Row
        style={{ marginBottom: "60px", marginTop: "60px", marginLeft: "30px" }}
      >
	  

        <Col>
		  <h2 className="cipa-h2">Dias sem acidentes: {totalDias}</h2>
          <br />
		  <br />
		  <br />
        </Col>
		
        <h2 className="cipa-h2">Outras informações</h2>
        <p className="cipa-p">
          A Fatec possui uma maca e uma cadeira de rodas próximo à entrada
          principal e uma maca no quarto andar. Em caso de necessidade chamar o
          Ramal 21.
		  <br />
		  <br />
		  <br />
        </p>
		
        <h2 className="cipa-h2">
          Recomendações da CIPA aos colaboradores e frequentadores das
          dependências da Fatec SJC
        </h2>
        <p className="cipa-p">
          - 09/10/2018 – Estacione seu veículo de ré. Isso evita acidentes e em
          caso de emergência facilita a saída de todos;
          <br />- 09/10/2018 - Não sente nas escadas, não utilize celular nas
          escadas.
		  <br />
		  <br />
		  <br />
        </p>
		
        <h2 className="cipa-h2">Contato</h2>
        <p className="cipa-p">
          <a
            id="cor"
            target="_blank" rel="noopener noreferrer"
            href="https://fatecsjc-prd.azurewebsites.net/downloads/cipa/2015.06.25_perguntas_frequentes_cipa.pdf"
          >
            Clique aqui
          </a>{" "}
          para ler o conteúdo de "Perguntas frequentes" e veja se sua dúvida já
          tem uma resposta.
          <br />
          Em caso de urgência, ligue diretamente para a unidade{" "}
          <mark id="marca">+55 (12) 3905-2423</mark> ou pelo e-mail{" "}
          <mark id="marca">f146cipa@cps.sp.gov.br</mark>
        </p>
      </Row>
      <Row>
        <h2 className="cipa-h2">Atribuições Legais (NR 5-16)</h2>
        <h2 className="cipa-h2">
          Regida pela Norma Regulamentadora Nº5 do Ministério do Trabalho e
          Emprego (NR-5)
        </h2>
        <p className="cipa-p">
          Participar da implementação e do controle da qualidade das medidas de
          prevenção necessárias, bem como da avaliação das prioridades de ação
          nos locais de trabalho;
          <br />
          Realizar, periodicamente, verificações nos ambientes e condições de
          trabalho visando a identificação de situações que venham a trazer
          riscos para a segurança e saúde dos trabalhadores;
          <br />
          Realizar, a cada reunião, avaliação do cumprimento das metas fixadas
          em seu plano de trabalho e discutir as situações de risco que foram
          identificadas;
          <br />
          Divulgar aos trabalhadores informações relativas à segurança e saúde
          no trabalho;
          <br />
          Participar, com o SESMT, onde houver, das discussões promovidas pelo
          empregador, para avaliar os impactos de alterações no ambiente e
          processo de trabalhos relacionados à segurança e saúde dos
          trabalhadores;
          <br />
          Requerer ao SESMT, quando houver, ou ao empregador, a paralisação de
          máquina ou setor onde considere haver risco grave e iminente à
          segurança e saúde dos trabalhadores;
          <br />
          Requisitar à empresa as cópias das CAT emitidas;
          <br />
          Promover, anualmente, em conjunto com o SESMT, onde houver, a Semana
          Interna de Prevenção de Acidentes do Trabalho – SIPAT; e<br />{" "}
          Participar, anualmente, em conjunto com a empresa, de Campanhas de
          Prevenção da AIDS.
        </p>
      </Row>
    </Container>
    <Footer />{" "}
  </App>
);

export default cipa;
