import React from "react";
import Footer from "../components/footer";
import App from "../containers/App";
import biblion from "../components/images/biblion.png";
import {
  Container,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import "./biblioteca.css";

const biblioteca = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <p className="bbl-titulo">BIBLIOTECA</p>
    </Container>
    <Container>
      <p className="bbl-titulo">Horário de Funcionamento</p>
      <p id="subtitulo">Empréstimos / Devoluções / Renovações / Utilização do espaço da Biblioteca</p>
      
	  <Table striped bordered hover>
        <thead>
          <tr>
            <th>Segunda</th>
            <th>Terça</th>
            <th>Quarta</th>
            <th>Quinta</th>
            <th>Sexta</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>das 08h00 às 22h50</td>
            <td>das 08h00 às 22h50</td>
            <td>das 08h00 às 22h50</td>
            <td>das 08h00 às 22h50</td>
            <td>das 08h00 às 22h50</td>
          </tr>
        </tbody>
      </Table>
	  
{/*	  <Table striped bordered hover>
        <thead>
          <tr>
            <th>Período</th>            
            <th>Segunda</th>
            <th>Terça</th>
            <th>Quarta</th>
            <th>Quinta</th>
            <th>Sexta</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Manhã</td>            
            <td>08h00 às 13h00</td>
            <td>08h00 às 13h00</td>
            <td>08h00 às 13h00</td>
            <td>08h00 às 13h00</td>
            <td>08h00 às 13h00</td>
          </tr>
          <tr>
            <td>Tarde</td>  
            <td>14h00 às 18h00</td>
            <td>14h00 às 18h00</td>
            <td>13h00 às 18h00</td>
            <td>14h00 às 18h00</td>
            <td>14h00 às 18h00</td>
          </tr>
          <tr>
            <td>Noite</td>  
            <td>19h00 às 22h50</td>
            <td>19h00 às 22h50</td>
            <td>19h00 às 22h50</td>
            <td>19h00 às 22h50</td>
            <td>19h00 às 22h50</td>
          </tr>
        </tbody>
      </Table>
*/}
	  
      <br />
      <p className="biblioteca-p">
        Em caso de dúvida enviar um e-mail para: {" "}
        <mark id="marca">
        f146.biblioteca@fatec.sp.gov.br
        </mark>

      </p><br /><br />
	  

      <p className="bbl-titulo">Regras Gerais da Biblioteca</p>

      <Col md={{ span: 12 }} style={{ marginBottom: "40px" }}>
          <p className="biblioteca-p">
            <br /> - Para empréstimos de livros, é necessário que o aluno esteja em posse de qualquer documento com nome inteiro e foto (RG, CNH, etc.);
            <br /> - Quantidade de até 2 livros por aluno;
            <br /> - Os livros podem ser emprestados pelo prazo de 7 dias, podendo ser renovados por mais 7 dias, (caso a data de devolução caia em algum feriado, as devoluções ficarão para o 1º dia útil SUBSEQUENTE);
            <br /> - Os livros não serão ser renovados, se houver reserva na lista de espera para outro aluno, porém ficará a critério do aluno em posse do livro, devolver o livro dentro do prazo ou entregar com atraso, arcando com os devidos valores de multa;
            <br /> - Em caso de atraso na devolução dos livros, será cobrado multa no valor de R$1,00 por dia útil, POR CADA LIVRO;
            <br /> - Em caso de perda ou grandes danos no livro, o aluno deverá comunicar o funcionário da biblioteca, e fazer a reposição;
            <br /> - É livre para todos, a utilização dos computadores da biblioteca, respeitando a ordem de chegada de cada usuário, assim como as mesas com os cabos “Ethernet” para os notebooks;
            <br /> - É proibido a emissão de sons com a utilização de aparelhos de qualquer espécie (celulares, tablets, notebooks, etc.) dentro da biblioteca, utilizem fones de ouvido, em caso de chamadas, favor se retirar temporariamente da biblioteca;
            <br /> - Não será permitido adentrar na área dos livros (acervo), com bolsas, mochilas ou capacetes;
            <br /> - É proibido o empréstimo residencial dos Trabalhos de Graduação (TG’s) e Manuais de Aeronáutica. Estes itens são somente para consulta na biblioteca;
            <br /> - É proibido consumir alimentos ou bebidas dentro da biblioteca.
          </p>
      </Col>
	  
	  <br /><br /><br />

      <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <img className="aero-imagens" src={biblion} title="BibliON" alt=""></img>
      </Row>
    </Container>
    
    <p id="subtitulo">PERGUNTAS FREQUENTES</p>
    <p className="biblioteca-titulo"><b>O QUE É A BIBLION?</b></p>
    <p className="biblioteca-p">
      É a biblioteca digital gratuita de São Paulo. São milhares de livros digitais para ler onde e como quiser, além de uma vasta grade de programação cultural, como clubes de leitura, atividades de formação e muito mais. A BibliON é uma iniciativa do Governo do Estado de São Paulo, por meio da Secretaria de Cultura e Economia Criativa do Estado de São Paulo, sob a gestão da SP Leituras.
    </p>
    <br />
    <p className="biblioteca-titulo"><b>PRECISO PAGAR PARA USAR A BIBLION?</b></p>
    <p className="biblioteca-p">
      A biblioteca digital é pública e totalmente gratuita.
    </p>
    <br />
    <p className="biblioteca-titulo"><b>QUANTO TEMPO POSSO FICAR COM O LIVRO?</b></p>
    <p className="biblioteca-p">
      Você pode aproveitar a leitura por 15 dias e fazer a renovação uma vez pelo mesmo prazo, caso a obra não tenha reservas.
    </p>
    <br />
    <p className="biblioteca-titulo"><b>QUANTAS OBRAS POSSO EMPRESTAR?</b></p>
    <p className="biblioteca-p">
      Na BibliON você pode emprestar duas obras por vez.
    </p>


      <br />
    </Container>
    <Footer />
  </App>
);

export default biblioteca;
