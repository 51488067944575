import React from "react";
import Footer from "../components/footer";
import { Card, CardGroup, Container, Row, Col, Table } from "react-bootstrap";
import App from "../containers/App";
import "./calendario-digital-de-eventos.css";

const calendar_event = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col md={{ span: 12 }} style={{ marginTop: "30px" }}>
          <p className="eventos-titulo">CALENDÁRIOS DE EVENTOS </p>
          <iframe title="Calendário Digital de Eventos" src="https://www.google.com/calendar/embed?showTitle=0&amp;showPrint=0&amp;showTz=0&amp;height=900&amp;wkst=1&amp;hl=pt_BR&amp;bgcolor=%23ffffff&amp;src=unrbf7j3j4fjtvdjjmmb7ltdjs%40group.calendar.google.com&amp;color=%23711616&amp;ctz=America%2FSao_Paulo"></iframe>
        </Col>
      </Row>
    </Container>

    <Footer />
  </App>
);

export default calendar_event;
