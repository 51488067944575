import React from "react";

import App from "../containers/App";

const escola_inovadores = () => (
  <App>
    <h1>Escola de Inovadores</h1>
  </App>
);

export default escola_inovadores;