import React from "react";
import Footer from "../components/footer";
import App from "../containers/App";
import { Card, CardGroup, Container, Row, Col, Button } from "react-bootstrap";
import "./laboratorios-de-informatica.css";
import { MdSubdirectoryArrowRight } from "react-icons/md";

const laboratorio = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <p className="lab-titulo">LABORATÓRIOS DE INFORMÁTICA</p>
        </Col>
      </Row>
    </Container>

    <Container style={{ marginBottom: "50px" }}>
      <Row style={{ marginBottom: "30px" }}>
        <Col md={{ span: 12 }}>
          <p className="lab-titulo">Horário de Funcionamento</p>
          <p className="lab-texto">
            De segunda à sexta-feira das 07h10 às 23h00.
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: "30px" }}>
        <Col md={{ span: 12 }}>
          <p className="lab-titulo">Organização</p>
          <p className="lab-texto">
            Os laboratórios da FATEC-SJC prestam serviços de atendimento à comunidade acadêmica, oferecem recursos computacionais a serem utilizados nas disciplinas dos
			cursos regulares e em cursos extracurriculares ministrados na instituição.
          </p>
          <p className="lab-texto">
            A responsabilidade dos laboratórios estará a cargo do Diretor da FATEC-SJC e será coordenado pelo professor usuário do laboratório que é o responsável geral pelas 
			atividades desenvolvidas durante a sua aula.
          </p>
        </Col>
      </Row>

      <Row style={{ marginBottom: "30px" }}>
        <Col md={{ span: 12 }}>
          <p className="lab-titulo">Usuários</p>
          <p className="lab-texto">
            São considerados usuários dos Laboratórios de Informática, todos os Professores, Funcionários e Alunos, sejam da FATEC-SJC ou de cursos extracurriculares.
          </p>
          <p className="lab-texto">
            As normas para a utilização dos laboratórios aplicam-se a todos os usuários, estando os infratores sujeitos às penalidades.
          </p>
          <p className="lab-texto">
            Na realização de cursos, que não os de Graduação, poderão utilizar os laboratórios os participantes destes, desde que acompanhados do responsável pelo referido curso.
          </p>
        </Col>
      </Row>

      <Row style={{ marginBottom: "30px" }}>
        <Col md={{ span: 12 }}>
          <p className="lab-titulo">Infrações previstas</p>
          <p className="lab-texto">
            O mau uso do espaço ou dos recursos disponibilizados nos laboratórios por parte dos usuários poderá acarretar em penalidades definidas pela Coordenação do Curso ou do Diretor da FATEC-SJC.
          </p>
        </Col>
      </Row>

      <Row style={{ marginBottom: "30px" }}>
        <Col md={{ span: 12 }}>
          <p className="lab-titulo">Regras de uso dos laboratórios</p>
          <p className="lab-texto">
            <MdSubdirectoryArrowRight></MdSubdirectoryArrowRight> Não comer e/ou beber no interior dos laboratórios.
          </p>
          <p className="lab-texto">
            <MdSubdirectoryArrowRight></MdSubdirectoryArrowRight> Manter o laboratório sempre organizado.
          </p>
        </Col>
      </Row>
    </Container>

    <Footer />
  </App>
);

export default laboratorio;
