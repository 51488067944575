import React from "react";
import App from "../../containers/App";
import Footer from "../../components/footer";
import Image from "react-bootstrap/Image";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import perfil from "../../components/images/docentes/profile.png";
import arakaki from "../../components/images/docentes/reinaldo_gen_ichiro_arakaki.jpg";
import andrea_carvalho from "../../components/images/docentes/andrea_carvalho.jpg";
import claudio from "../../components/images/docentes/claudio_etelvino.png";
import diogo from "../../components/images/docentes/diogo_branquinho_ramos.jpg";
import sakaue from "../../components/images/docentes/eduardo_sakaue.jpg";
import eliane from "../../components/images/docentes/eliane_penha_mergulhao_dias.jpg";
import mineda from "../../components/images/docentes/emanuel_mineda.png";
import sabha from "../../components/images/docentes/fabiano_sabha_walczak.jpg";
import fabricio from "../../components/images/docentes/fabricio_galende.png";
import masanori from "../../components/images/docentes/fernando_masanori.png";
import gerson from "../../components/images/docentes/gerson_da_penha_neto.jpg";
import giuliano from "../../components/images/docentes/giuliano.png";
import hudson from "../../components/images/docentes/hudson_alberto.png";
import jean from "../../components/images/docentes/jean_carlos_lourenco_costa.jpg";
import walmir from "../../components/images/docentes/jose_walmir_goncalves_duque.png";
import juliana from "../../components/images/docentes/juliana_forin.png";
import lise from "../../components/images/docentes/lise_virginia_vieira_de_azevedo.jpg";
import tiago_colombo from "../../components/images/docentes/tiago_colombo.jpg";
import "./docentes.css";

const lista = [
  {
    nome: "Reinaldo Gen Ichiro Arakaki (Coordenador)",
    cv: "http://lattes.cnpq.br/2075406788034578",
    img: arakaki,
  },
  {
    nome: "Andrea Marques de Carvalho",
    cv: "http://lattes.cnpq.br/6731684455957819",
    img: andrea_carvalho,
  },
  {
    nome: "Arley Ferreira de Souza",
    cv: "http://lattes.cnpq.br/3013376353724630",
    img: perfil,
  },
  {
    nome: "Cláudio Etelvino de Lima",
    cv: "http://lattes.cnpq.br/9330552327454666",
    img: claudio,
  },
  {
    nome: "Diogo Branquinho Ramos",
    cv: "http://lattes.cnpq.br/2442951597646811",
    img: diogo,
  },
  {
    nome: "Eduardo Sakaue",
    cv: "http://lattes.cnpq.br/5948825528321491",
    img: sakaue,
  },
  {
    nome: "Eliane Penha Mergulhão Dias",
    cv: "http://lattes.cnpq.br/8261748179517367",
    img: eliane,
  },
  {
    nome: "Emanuel Mineda Carneiro",
    cv: "http://lattes.cnpq.br/3395132054636969",
    img: mineda,
  },
  {
    nome: "Fabiano Sabha Walczak",
    cv: "http://lattes.cnpq.br/8632801813322469",
    img: sabha,
  },
  {
    nome: "Fabrício Galende Marques de Carvalho",
    cv: "http://lattes.cnpq.br/8632801813322469",
    img: fabricio,
  },
  {
    nome: "Fernando Masanori Ashikaga",
    cv: "http://lattes.cnpq.br/3324339924853354",
    img: masanori,
  },
  {
    nome: "Gerson da Penha Neto",
    cv: "http://lattes.cnpq.br/9441903297380731",
    img: gerson,
  },
  {
    nome: "Giuliano Araújo Bertoti",
    cv: "http://lattes.cnpq.br/4377240827813491",
    img: giuliano,
  },
  {
    nome: "Hudson Alberto Bode",
    cv: "http://lattes.cnpq.br/3555037033356299",
    img: hudson,
  },
  {
    nome: "Jean Carlos Lourenço Costa",
    cv: "http://lattes.cnpq.br/4723982029081265",
    img: jean,
  },
  {
    nome: "José Walmir Gonçalves Duque",
    cv: "http://lattes.cnpq.br/4678097512178887",
    img: walmir,
  },
  {
    nome: "Juliana Forin Pasquini Martinez",
    cv: "http://lattes.cnpq.br/1506784529918492",
    img: juliana,
  },
  {
    nome: "Lise Virgínia Vieira de Azevedo",
    cv: "http://lattes.cnpq.br/7441288591542440",
    img: lise,
  },
  {
    nome: "Rogério Benedito de Andrade",
    cv: "",
    img: perfil,
  },
  {
    nome: "Tiago Cristofer Aguzzoli Colombo",
    cv: "http://lattes.cnpq.br/5386299544469271",
    img: tiago_colombo,
  },
];

const doc_dsm = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
      }}
    >
      <Row>
        <Col>
          <p className="titulo">DOCENTES</p>
          <p className="subtitulo">
            DESENVOLVIMENTO DE SOFTWARE MULTIPLATAFORMA
          </p>
        </Col>
      </Row>
    </Container>
    <Container style={{ marginBottom: "20px" }}>
      <Button href="/docentes">Voltar para a área de Docentes</Button>
    </Container>
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "50px",
      }}
    >
      <Row xs={1} md={4} className="g-4">
        {lista.map((obj) => (
          <Col>
            <a className="nome" target="_blank" rel="noopener noreferrer" href={obj.cv}>
              <Card className="doc_card">
                <Card.Body>
                  <Card.Title>
                    <Image
                      className="image"
                      roundedCircle
                      src={obj.img}
                      width={150}
                      height={150}
                    ></Image>
                  </Card.Title>
                  <Card.Text>{obj.nome}</Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </Container>

    <Footer />
  </App>
);

export default doc_dsm;
