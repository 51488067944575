import React from "react";
import App from "../../containers/App";
import Footer from "../../components/footer";
import {
  Card,
  CardGroup,
  Container,
  Row,
  Col,
  ListGroup,
} from "react-bootstrap";
import "../sobre-cursos.css";

const geral_alunos = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "10px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <p className="sbr-titulo">ÁREA DO ALUNO</p>
        </Col>
      </Row>
    </Container>

    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "100px",
      }}
    >
      <Row xs={1} md={11} className="g-4">
        <Col>
          <p className="sbr-list-titulo">Lista de Opções</p>
          <ListGroup variant="flush">
            <a className="sbr-links" href="/calendario-academico">
              <ListGroup.Item variant="warning">
			    Calendário Acadêmico
			  </ListGroup.Item>
            </a>
			<a className="sbr-links" href="/catalogo-de-tg">
              <ListGroup.Item>
				Catálogo de TG
			  </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/email-institucional">
              <ListGroup.Item variant="warning">
                Email Institucional
              </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/horarios-de-aula">
              <ListGroup.Item>
				Horários de Aula
			  </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/horarios-de-onibus">
              <ListGroup.Item variant="warning">
				Horários de ônibus
			  </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/monitoria">
              <ListGroup.Item>
				Monitoria
			  </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/normas-para-tg">
              <ListGroup.Item variant="warning">
				Normas para TG
			  </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/passe-escolar">
              <ListGroup.Item>
				Passe Escolar
			  </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/portal-de-carreiras">
              <ListGroup.Item variant="warning">
				Portal de Carreiras
			  </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/setor-de-estagios">
              <ListGroup.Item>
                Setor de Estágios
              </ListGroup.Item>
            </a>
          </ListGroup>
        </Col>
      </Row>
    </Container>

    <Footer />
  </App>
);

export default geral_alunos;
