// /src/components/Routes.js
import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Home from "../pages/Home";

import geral_institucional from "../pages/geral/geral_institucional";
	import sobre from "../pages/sobre";
	import achados_e_perdidos from "../pages/achados-e-perdidos";
	import biblioteca from "../pages/biblioteca";
	import calendario_digital_de_eventos from "../pages/calendario-digital-de-eventos";
	import centro_de_memoria from "../pages/centro-de-memoria";
	import cipa from "../pages/cipa";
	import concurso_publico_pss from "../pages/concurso-publico-pss";
	import corpo_administrativo from "../pages/corpo-administrativo";
	import cpa from "../pages/cpa";
	import docentes from "../pages/docentes";
	import editais from "../pages/editais";
	import laboratorios_informatica from "../pages/laboratorios-de-informatica";
	import secretaria from "../pages/secretaria";

import geral_cursos from "../pages/geral/geral_cursos";
	import curso_ads from "../pages/curso-analise-e-desenvolvimento-de-sistemas";
	import curso_bd from "../pages/curso-banco-de-dados";
	import curso_dsm from "../pages/curso-desenvolvimento-de-software-multiplataforma";
	import curso_gpi from "../pages/curso-gestao-da-producao-industrial";
	import curso_ge_ead from "../pages/curso-gestao-empresarial-ead";
	import curso_log from "../pages/curso-logistica";
	import curso_mav from "../pages/curso-manufatura-avancada";
	import curso_mnt from "../pages/curso-manutencao-de-aeronaves";
	import curso_pea from "../pages/curso-projetos-de-estruturas-aeronauticas";
	import sobre_cursos from "../pages/sobre-cursos";
	import vestibular from "../pages/vestibular";

import geral_projetos from "../pages/geral/geral_projetos";
	import aerodesign from "../pages/aerodesign";
	//agencia inova paula souza => https://inova.cps.sp.gov.br/
	import api from "../pages/aprendizagem-por-projetos-integrados";
	import baja from "../pages/baja";
	import cadi from "../pages/cadi";
	//cimatech => https://www.cimatech.com.br/
	import escola_de_inovadores from "../pages/escola-de-inovadores";
	import business_mentoring from "../pages/business-mentoring";
	//nucleo de relacoes internacionais => https://nrifatec.wordpress.com/
	import vestec from "../pages/vestec";
	import workshop from "../pages/workshop";

import geral_alunos from "../pages/geral/geral_alunos";
	import calendario_academico from "../pages/calendario-academico";
	import catalogo_de_tg from "../pages/catalogo-de-tg";
	import email_institucional from "../pages/email-institucional";
	import horarios_de_aula from "../pages/horarios-de-aula";
	import horarios_de_onibus from "../pages/horarios-de-onibus";
	import monitoria from "../pages/monitoria";
	import normas_para_tg from "../pages/normas-para-tg";
	import passe_escolar from "../pages/passe-escolar";
	import portal_de_carreiras from "../pages/portal-de-carreiras";
	import rematricula from "../pages/rematricula";
	import setor_de_estagios from "../pages/setor-de-estagios";

import contato from "../pages/contato";

//pagina para os docentes de cada curso
import docentes_ads from "../pages/docentes/docentes-ads";
import docentes_bd from "../pages/docentes/docentes-bd";
import docentes_dsm from "../pages/docentes/docentes-dsm";
import docentes_gpi from "../pages/docentes/docentes-gpi";
import docentes_log from "../pages/docentes/docentes-log";
import docentes_mav from "../pages/docentes/docentes-mav";
import docentes_mnt from "../pages/docentes/docentes-mnt";
import docentes_pea from "../pages/docentes/docentes-pea";

import Footer from "./footer/Footer";


const Routes = () => (
  <BrowserRouter>
    <Route exact path="/" component={Home} />
	
	<Route exact path="/geral_institucional" component={geral_institucional} />
		<Route exact path="/sobre" component={sobre} />
		<Route exact path="/achados-e-perdidos" component={achados_e_perdidos} />
		<Route exact path="/biblioteca" component={biblioteca} />
		<Route exact path="/centro-de-memoria" component={centro_de_memoria} />
		<Route exact path="/cipa" component={cipa} />
		<Route exact path="/calendario-digital-de-eventos" component={calendario_digital_de_eventos} />
		<Route exact path="/concurso-publico-pss" component={concurso_publico_pss} />
		<Route exact path="/cpa" component={cpa} />
		<Route exact path="/corpo-administrativo" component={corpo_administrativo} />
		<Route exact path="/docentes" component={docentes} />
		<Route exact path="/editais" component={editais} />
		<Route exact path="/laboratorios-de-informatica" component={laboratorios_informatica} />
		<Route exact path="/secretaria" component={secretaria} />	
	
	<Route exact path="/geral_cursos" component={geral_cursos} />
		<Route exact path="/curso_ads" component={curso_ads} />
		<Route exact path="/curso_bd" component={curso_bd} />
		<Route exact path="/curso_dsm" component={curso_dsm} />
		<Route exact path="/curso_ge_ead" component={curso_ge_ead} />
		<Route exact path="/curso_gpi" component={curso_gpi} />
		<Route exact path="/curso_log" component={curso_log} />
		<Route exact path="/curso_mav" component={curso_mav} />
		<Route exact path="/curso_mnt" component={curso_mnt} />
		<Route exact path="/curso_pea" component={curso_pea} />
		<Route exact path="/sobre_cursos" component={sobre_cursos} />
		<Route exact path="/vestibular" component={vestibular} />
	
	<Route exact path="/geral_projetos" component={geral_projetos} />
		<Route exact path="/aerodesign" component={aerodesign} />
		<Route exact path="/aprendizagem-por-projetos-integrados" component={api} />
		<Route exact path="/baja" component={baja} />
		<Route exact path="/cadi" component={cadi} />
		<Route exact path="/escola-de-inovadores" component={escola_de_inovadores} />
		<Route exact path="/business-mentoring" component={business_mentoring} />
		<Route exact path="/vestec" component={vestec} />
		<Route exact path="/workshop" component={workshop} />
	
	<Route exact path="/geral_alunos" component={geral_alunos} />
		<Route exact path="/calendario-academico" component={calendario_academico} />
		<Route exact path="/catalogo-de-tg" component={catalogo_de_tg} />
		<Route exact path="/email-institucional" component={email_institucional} />
		<Route exact path="/horarios-de-aula" component={horarios_de_aula} />
		<Route exact path="/horarios-de-onibus" component={horarios_de_onibus} />
		<Route exact path="/monitoria" component={monitoria} />
		<Route exact path="/normas-para-tg" component={normas_para_tg} />
		<Route exact path="/passe-escolar" component={passe_escolar} />
		<Route exact path="/portal-de-carreiras" component={portal_de_carreiras} />
		<Route exact path="/rematricula" component={rematricula} />
		<Route exact path="/setor-de-estagios" component={setor_de_estagios} />

    <Route exact path="/contato" component={contato} />

    <Route exact path="/docentes-ads" component={docentes_ads} />
    <Route exact path="/docentes-bd" component={docentes_bd} />
    <Route exact path="/docentes-dsm" component={docentes_dsm} />
	<Route exact path="/docentes-gpi" component={docentes_gpi} />
    <Route exact path="/docentes-log" component={docentes_log} />
	<Route exact path="/docentes-mav" component={docentes_mav} />
    <Route exact path="/docentes-mnt" component={docentes_mnt} />
	<Route exact path="/docentes-pea" component={docentes_pea} />

    <Route exact path="/footer" component={Footer} />
	
  </BrowserRouter>
);

export default Routes;
