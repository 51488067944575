import React from "react";
import App from "../containers/App";
import Footer from "../components/footer";
import { Card, CardGroup, Container, Row, Col, Button } from "react-bootstrap";
import "./curso-gestao-empresarial-ead.css";

const introducao = [
  {
    titulo: "Resumo",
    descricao:
      "O Curso Superior de Tecnologia em Gestão Empresarial (Processos Gerenciais) forma profissionais que aliam o conhecimento específico das técnicas e práticas de gestão ao conhecimento geral multidisciplinar, que os torna aptos para atuar de forma eficiente, ética e sustentável, em processos gerenciais de negócios tradicionais e das chamadas novas economias do mundo do trabalho contemporâneo. A habilidade e empatia para lidar com pessoas, a capacidade de comunicação e diálogo, a disposição para trabalho em equipe, a flexibilidade, a competência em negociação, curiosidade de pesquisar e buscar informações, a capacidade de tomada de decisão em contextos econômicos, políticos, culturais e sociais distintos, são requisitos importantes a esse profissional. O curso se estrutura então, basicamente, em disciplinas do eixo técnico-profissional, disciplinas do eixo multidisciplinar e componentes de projeto que vão buscar integrar conhecimentos construídos pelo estudante, dentro e fora do contexto do curso.",
  },
  {
    titulo: "Onde este tecnólogo poderá trabalhar?",
    descricao:
      "ALGUMAS ÁREAS DE ATUAÇÃO.... em seu próprio negócio (consultoria, turismo, comércio, indústria, etc.); em micro, pequenas e médias empresas; no setor público; em entidades particulares, mistas ou coletivas: cooperativas, associações, organizações não governamentais, empreendimentos de economia criativa, economia colaborativa, e outros modelos de novos negócios sustentáveis.",
  },
];

const links = [
  {
    titulo: "Inscreva-se",
    subtitulo: "40 Vagas > EaD > Duração de 06 semestres",
    link: "https://www.vestibularfatec.com.br/home/",
    botao: " www.vestibularfatec.com.br",
  },
  {
    titulo: "Matriz curricular",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/ge/matriz_curricular_ge-ead.pdf",
    botao: "Clique aqui para visualizar a matriz curricular e a carga horária",
  },
  {
    titulo: "Projeto Pedagógico",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/ge/projeto_pedagogico_ge-ead.pdf",
    botao: "Clique aqui para visualizar o projeto pedagógico do curso.",
  },
  {
    titulo: "Regulamento Geral dos Cursos de Graduação das Fatecs",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/arquivos/regulamento_geral_fatecs.pdf",
    botao: "Clique aqui para visualizar o Regulamento",
  },
  {
    titulo: "Regime Disciplinar Discente",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/arquivos/regime_disciplinar_discente.pdf",
    botao: "Clique aqui para visualizar o Regime Disciplinar Discente",
  },
];

const gtproducao_ind = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <p className="gtp-titulo">GESTÃO EMPRESARIAL (EaD)</p>
          <p className="gtp-subtitulo">
            40 Vagas EaD | Gestão e Negócios
          </p>
        </Col>
      </Row>
    </Container>

    <Container id="resumo" style={{ marginBottom: "50px" }}>
      <Row>
        <Col md={{ span: 12 }}>
          <CardGroup>
            {introducao.map((object, index) => {
              return (
                <Card style={{ marginRight: "50px" }}>
                  <Card.Body>
                    <Card.Title className="gtp-card-titulo">
                      {object.titulo}
                    </Card.Title>
                    <Card.Text className="gtp-card-descricao">
                      {object.descricao}
                    </Card.Text>
                  </Card.Body>
                </Card>
              );
            })}
          </CardGroup>
        </Col>
      </Row>
    </Container>
	
    <Container style={{ marginBottom: "50px", textAlign: "center" }}>
      <Row xs={1} md={2} className="g-4">
        {links.map((object, index) => {
          return (
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title className="gtp-card-link-titulo">
                    {object.titulo}
                  </Card.Title>
                  <Card.Text className="gtp-card-link-subtitulo">
                    {object.subtitulo}
                  </Card.Text>
                  <Button
                    className="gtp-card-botao"
                    variant="outline-success"
                    href={object.link}
                    target="_blank"
                  >
                    {object.botao}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>

    <Footer />
  </App>
);

export default gtproducao_ind;
